<template>
  <div class="shadow-box filter-ty">
    <div class="list is-gapless is-bottomless is-topless">
      <div class="list-title list-content">
        <span class="has-text-weight-bold has-text-primary is-size-5">{{ $t('components.FiltersMeal.title') }}</span>
      </div>
      <div class="list-action">
        <el-button
          v-if="touched"
          type="text"
          class="has-text-secondary has-text-uppercase is-size-7"
          @click="handleClear">
          {{ $t('components.FiltersMeal.clear') }}
        </el-button>
      </div>
    </div>
    <div class="list is-gapless">
      <div class="list-content">
        <ul class="list-checkbox-half">
          <ListCheckBox
            v-for="it in meals"
            :key="it.key"
            v-model="localValue[it.key]"
            @change="handleChanged">
            <TrustYouMealLabel :tier-key="it.key" />
            <span> ({{ getMealCount(it.key) }})</span>
          </ListCheckBox>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { meals } from '../common'
import TrustYouMealLabel from './TrustYouMealLabel'
import ListCheckBox from './ListCheckBox'
import { mapGetters } from 'vuex'

export default {
  name: 'FiltersMeal',
  components: {
    [TrustYouMealLabel.name]: TrustYouMealLabel,
    [ListCheckBox.name]: ListCheckBox
  },
  props: {
    value: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    layoutMode: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      localValue: {},
      timer: undefined,
      meals: Object.values(meals)
    }
  },
  computed: {
    touched () {
      return Object.values(this.localValue).filter(_ => _).length > 0
    },
    delay () {
      return this.layoutMode.includes('mobile') ? 0 : 800
    },
    ...mapGetters('searchMany', ['getMealCount'])
  },
  watch: {
    'value': {
      immediate: true,
      handler (nV) {
        this.localValue = { ...nV } // JSON.parse(JSON.stringify(nV))
      }
    }
  },
  methods: {
    reset () {
      this.localValue = {}
    },
    handleClear () {
      this.reset()
      this.$emit('input', this.localValue)
    },
    handleChanged () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$emit('input', this.localValue)
      }, this.delay)
    }
    // ,
    // getMealPlanCount () {
    //   return store.searchMany.getMealCount(key.split('')[1])
    // }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .list-label {
    opacity: 0.68;
  }
  .filter-ty {
    /* padding: $bleed $bleed * 2; */
  }
  .filter-ty--touched {
    font-weight: 600;
    color: $text;
  }
</style>
