<template>
  <g>
    <path d="M23.2988 21.8602L17.5789 15.9194C19.0496 14.1735 19.8554 11.9768 19.8554 9.68999C19.8554 4.34701 15.5023 0 10.1519 0C4.80154 0 0.448486 4.34701 0.448486 9.68999C0.448486 15.033 4.80154 19.38 10.1519 19.38C12.1605 19.38 14.0747 18.775 15.7112 17.6265L21.4746 23.6124C21.7155 23.8622 22.0395 24 22.3867 24C22.7154 24 23.0271 23.8749 23.2638 23.6474C23.7667 23.1641 23.7827 22.3628 23.2988 21.8602ZM10.1519 2.52782C14.1067 2.52782 17.324 5.74069 17.324 9.68999C17.324 13.6393 14.1067 16.8522 10.1519 16.8522C6.19715 16.8522 2.97982 13.6393 2.97982 9.68999C2.97982 5.74069 6.19715 2.52782 10.1519 2.52782Z" />
  </g>
</template>
<script>
export default {
  name: 'IconSearch'
}
</script>
