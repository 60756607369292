<template>
  <el-checkbox
    v-model="model"
    class="quote-checkbox" />
</template>

<script>
export default {
  name: 'QuoteCheckbox',
  props: {
    property: {
      type: Object,
      required: true
    }
  },
  computed: {
    model: {
      set (nv) {
        if (nv) {
          this.$store.commit('quoteSelector/ADD_ITEM', this.property)
        } else {
          this.$store.commit('quoteSelector/REMOVE_ITEM', this.property)
        }
      },
      get () {
        return this.$store.state.quoteSelector.list.some(item => {
          const property = item.property || {}
          const propertyFromProps = this.property.property || {}
          return property.propertyCode === propertyFromProps.propertyCode
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .quote-checkbox {
    position: absolute;
    left: 0.75rem;
    top: 0.55rem;
  }
</style>
