export const dataCategories = [
  {
    'id': 1,
    'label': 'Hotel',
    'cn': '酒店'
  },
  {
    'id': 2,
    'label': 'Motel',
    'cn': '汽车旅馆'
  },
  {
    'id': 3,
    'label': 'Resort',
    'cn': '度假村'
  },
  {
    'id': 4,
    'label': 'Inn',
    'cn': '客栈'
  },
  {
    'id': 5,
    'label': 'Bed & breakfast',
    'cn': '住宿和早餐'
  },
  {
    'id': 6,
    'label': 'Guesthouse',
    'cn': '宾馆'
  },
  {
    'id': 7,
    'label': 'Condo',
    'cn': '公寓'
  },
  {
    'id': 8,
    'label': 'All-inclusive property',
    'cn': '套餐服务'
  },
  // {
  //   'id': 9,
  //   'label': 'Cabin'
  // },
  // {
  //   'id': 10,
  //   'label': 'Chalet'
  // },
  // {
  //   'id': 11,
  //   'label': 'Cottage'
  // },
  {
    'id': 12,
    'label': 'Hostel/Backpacker accommodation',
    'cn': '旅社/背包客住宿'
  },
  // {
  //   'id': 13,
  //   'label': 'Ranch'
  // },
  // {
  //   'id': 14,
  //   'label': 'Villa'
  // },
  // {
  //   'id': 15,
  //   'label': 'Lodge'
  // },
  {
    'id': 16,
    'label': 'Apartment',
    'cn': '公寓'
  },
  // {
  //   'id': 17,
  //   'label': 'Private vacation home'
  // },
  // {
  //   'id': 18,
  //   'label': 'Houseboat'
  // },
  // {
  //   'id': 20,
  //   'label': 'Ryokan'
  // },
  // {
  //   'id': 21,
  //   'label': 'Tree house property'
  // },
  {
    'id': 22,
    'label': 'Aparthotel',
    'cn': '公寓酒店'
  },
  // {
  //   'id': 23,
  //   'label': 'Condominium resort'
  // },
  // {
  //   'id': 24,
  //   'label': 'Campsite'
  // },
  // {
  //   'id': 25,
  //   'label': 'Riad'
  // },
  {
    'id': 26,
    'label': 'Hostal',
    'cn': '招待所'
  }
  // {
  //   'id': 29,
  //   'label': 'Country House '
  // },
  // {
  //   'id': 30,
  //   'label': 'Pension'
  // },
  // {
  //   'id': 31,
  //   'label': 'Pousada (Portugal)'
  // },
  // {
  //   'id': 32,
  //   'label': 'Pousada (Brazil)'
  // },
  // {
  //   'id': 33,
  //   'label': 'Residence'
  // },
  // {
  //   'id': 34,
  //   'label': 'TownHouse'
  // },
  // {
  //   'id': 36,
  //   'label': 'Castle'
  // },
  // {
  //   'id': 37,
  //   'label': 'Safari/Tentalow'
  // },
  // {
  //   'id': 39,
  //   'label': 'Palace'
  // },
  // {
  //   'id': 40,
  //   'label': 'Agritourism property'
  // },
  // {
  //   'id': 41,
  //   'label': 'Cruise'
  // },
  // {
  //   'id': 42,
  //   'label': 'Holiday Park'
  // }
]
export const dataFacilities = [
  // {
  //   'id': 'airConditioning',
  //   'label': 'Air Conditioning',
  //   'cn':'空调'
  // },
  {
    'id': '3064',
    'label': 'Airport Transportation',
    'cn': '机场运输',
    'isForRoom': false
  },
  // {
  //   'id': 'childrenAllowed',
  //   'label': 'Children Allowed',
  //   'cn':'允许儿童'
  // },
  // {
  //   'id': 'clothingIron',
  //   'label': 'Clothing Iron',
  //   'cn':'熨斗'
  // },
  // {
  //   'id': 'coffeeTeaMaker',
  //   'label': 'Coffee Tea Maker',
  //   'cn':'咖啡茶壶'
  // },
  // {
  //   'id': 'continentalBreakfast',
  //   'label': 'Continental Breakfast',
  //   'cn':'欧式早餐'
  // },
  {
    'id': '128',
    'label': 'Dry Cleaning',
    'cn': '干洗',
    'isForRoom': false
  },
  // {
  //   'id': 'fitnessFacility',
  //   'label': 'Fitness Facility',
  //   'cn':'健身设施'
  // },
  {
    'id': '154',
    'label': 'Game Room',
    'cn': '娱乐室',
    'isForRoom': false
  },
  {
    'id': '235',
    'label': 'Hair Dryer',
    'cn': '吹风机',
    'isForRoom': false
  },
  // {
  //   'id': 'handicapAccessible',
  //   'label': 'Handicap Accessible',
  //   'cn':'无障碍'
  // },
  {
    'id': '29',
    'label': 'Indoor Pool',
    'cn': '室内游泳池',
    'isForRoom': false
  },
  {
    'id': '198',
    'label': 'Kitchen',
    'cn': '厨房',
    'isForRoom': true
  },
  {
    'id': '6',
    'label': 'Meeting Rooms',
    'cn': '会议室',
    'isForRoom': false
  },
  {
    'id': '85',
    'label': 'Mini Bar in Room',
    'cn': '室内迷你吧',
    'isForRoom': true
  },
  // {
  //   'id': 'nonSmokingRooms',
  //   'label': 'Non Smoking Rooms',
  //   'cn':'无烟客房'
  // },
  // {
  //   'id': 'outdoorPool',
  //   'label': 'Outdoor Pool',
  //   'cn':'室外游泳池'
  // },
  // {
  //   'id': 'petsAllowed',
  //   'label': 'Pets Allowed',
  //   'cn':'允许携带宠物'
  // },
  {
    'id': '16',
    'label': 'Rooms Service',
    'cn': '客房服务',
    'isForRoom': false
  },
  // {
  //   'id': 'twentyFourHourSecurity',
  //   'label': '24 Hours Security',
  //   'cn':'24小时保安'
  // },
  {
    'id': '149',
    'label': 'Valet Parking',
    'cn': '代客泊车',
    'isForRoom': false
  },
  {
    'id': '230',
    'label': 'Voice Mail',
    'cn': '语音邮件',
    'isForRoom': true
  }
  // {
  //   'id': 'wakeUpService',
  //   'label': 'Wake up Service',
  //   'cn':'唤醒服务'
  // },
  // {
  //   'id': 'whirpool',
  //   'label': 'Whirlpool',
  //   'cn':'涡流浴缸'
  // }
]
