<template>
  <span :class="['ty--label', tier.css]">{{ $t(`common.${tier.key}`) }}</span>
</template>

<script>
import { meals } from '../common'

export default {
  name: 'TrustYouMealLabel',
  props: {
    tierKey: String // m1 - m7
  },
  computed: {
    tier () {
      if (this.tierKey) {
        return meals[this.tierKey]
      }
      return {}
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  /* ------------- ty--label -------------- */
  .ty--label .score {
    color: #666;
  }
  /* ------------- Negative ---------------- */
  .ty--label.neg {
    color: #f37159;
  }

  /* -------------- Neutral ---------------- */
  .ty--label.neu {
    color: #f9c015;
  }

  .ty--label.neu .value {
    background: #f9c015;
  }

  /* ------------- Positive ---------------- */
  .ty--label.pos {
    color: #119a11;
  }
</style>
