<template>
  <div>
    <el-popover
      :disabled="!selected"
      :popper-options="{boundariesElement: 'body'}"
      placement="bottom"
      width="400"
      trigger="hover">
      <p class="quote--description">
        Select properties by ticking the box to the left of the hotel.
      </p>
      <hr
        v-if="itemCount > 0"
        style="margin: 10px 0;">
      <template v-for="(item) in list">
        <div
          v-if="!!item.property && item.property.propertyCode"
          :key="item.property.propertyCode"
          class="list is-gapless is-narrow  is-align-flex-start">
          <div class="list-content">
            {{ item.property.name }}
          </div>
          <div class="list-action">
            <el-link
              size="small"
              type="danger"
              @click="$store.commit('quoteSelector/REMOVE_ITEM',item)">
              remove
            </el-link>
          </div>
        </div>
      </template>
      <div
        slot="reference"
        :class="{active:selected}"
        class="top-bar-item top-bar-item-quote"
        @click="$store.commit('quoteSelector/TOGGLE_SELECT')">
        <IconBase
          :width="26"
          :height="26"
          class="icon">
          <IconDoc />
        </IconBase>
        <div class="has-text-weight-bold">
          Generate quote
        </div>
        <span
          v-if="hasItem"
          class="filter-count">{{ itemCount }}</span>
        <el-button
          v-show="selected"
          size="small"
          class="button-secondary button-quote"
          :class="{hasItem:hasItem}"
          @click.stop="quote">
          Prepare selection
        </el-button>
      </div>
    </el-popover>
  </div>
</template>

<script>
import IconDoc from './icons/iconDoc'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'QuoteButton',
  components: { IconDoc },
  computed: {
    ...mapGetters('quoteSelector', ['hasItem',
      'itemCount']),
    ...mapState('quoteSelector', ['selected',
      'list']),
    show () {
      return this.itemCount > 0
    }
  },
  deactivated () {
    this.$store.commit('quoteSelector/TOGGLE_SELECT', false)
  },
  methods: {
    quote () {
      if (this.hasItem) {
        const { page, ...query } = this.$route.query
        let quoteRoute = {
          name: 'quote',
          query: {
            ...query,
            propertyCodes: this.list.map(i => i.property.propertyCode).join(','),
            locationQuery: this.$route.params.locationQuery,
            markupType: 'Percentage',
            discountType: 'Percentage'
          }
        }
        this.$store.commit('quoteSelector/RESET')
        // this.$router.push(quoteRoute)
        const routeData = this.$router.resolve(quoteRoute)
        window.open(routeData.href, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .top-bar-item-quote {
    cursor: pointer;
    outline: none!important;
    &.active {
      background-color: #FEF6E6;
      outline: none!important;
    }
    .button-secondary {
      margin-left: 0.5rem;
    }
  }
  .button-quote {
    cursor: not-allowed;
    &.hasItem {
      cursor: pointer;
    }
  }

  .quote--description {
    width: 350px;
    word-break: normal;
  }
</style>
