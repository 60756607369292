<template>
  <div class="search-page">
    <div class="top-bar">
      <div class="top-bar-inner">
        <div
          class="top-bar-item top-bar-item-search"
          @click="searchDrawerActive=true">
          <IconBase class="icon">
            <IconSearch />
          </IconBase>
          <div
            class="has-text-ellipsis"
            style="max-width: 300px;">
            <div class="has-text-weight-bold has-text-ellipsis">
              {{ queryParams.locationQuery }}
            </div>
            <div class="is-size-7 has-text-ellipsis has-text-grey-light">
              <span class="m-r-2x">
                {{ queryParams.checkInDate |date('MMM D') }}
                -
                {{ queryParams.checkOutDate |date('MMM D') }}
              </span>
              <span class="m-r-2x">
                {{ queryParams.roomCount }} {{ $t('components.SearchGuest.room') }},
                {{ queryParams.adultCount }} {{ $t('components.SearchGuest.adults') }},
                {{ childrenCount }} {{ $t('components.SearchGuest.children') }}

              </span>
              <span class="m-r-2x">{{ queryParams.nationality }}</span>
            </div>
          </div>
        </div>
        <div class="divider-vertical" />
        <!-- sorting -->
        <div class="top-bar-item top-bar-item-sort">
          <el-dropdown
            :hide-on-click="true"
            @command="handleSortChanged">
            <div class="dropdown-handler">
              <span class="el-dropdown-link has-text-weight-bold">
                {{ $t('views.search.sort-by') }} <i class="el-icon-caret-bottom el-icon--right" />
              </span>
              <span class="is-block">{{ sortingLabel }}</span>
            </div>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :class="{ 'sort-active': sorting.sortBy === 'popular' }"
                command="popular">
                {{ $t('views.search.popularity') }}
              </el-dropdown-item>
              <el-dropdown-item
                :class="{ 'sort-active': sorting.sortBy === 'bookmark' }"
                command="bookmark">
                {{ $t('views.search.bookmark') }}
              </el-dropdown-item>
              <!--              <el-dropdown-item-->
              <!--                :class="{ 'sort-active': sorting.sortBy === 'adjustment' }"-->
              <!--                command="adjustment">-->
              <!--                {{ $t('views.search.discount') }}-->
              <!--              </el-dropdown-item>-->
              <el-dropdown-item
                :class="{ 'sort-active': sorting.sortBy === 'priceDsc' }"
                command="priceDsc">
                {{ $t('views.search.price-lowest-first') }}
              </el-dropdown-item>
              <el-dropdown-item
                :class="{ 'sort-active': sorting.sortBy === 'priceAsc' }"
                command="priceAsc">
                {{ $t('views.search.price-highest-first') }}
              </el-dropdown-item>
              <el-dropdown-item
                :class="{ 'sort-active': sorting.sortBy === 'trustYouDsc' }"
                command="trustYouDsc">
                {{ $t('views.search.reviews') }}
              </el-dropdown-item>
              <!-- <el-dropdown-item>{{$t('views.search.price')}}</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="divider-vertical" />
        <div
          class="top-bar-item top-bar-item-filters"
          @click="filterDrawerActive=true">
          <IconBase
            :width="26"
            :height="24"
            class="icon">
            <IconFilter />
          </IconBase>
          <div class="has-text-weight-bold">
            {{ $t('views.search.filters') }}
          </div>
          <span
            v-if="filterCount"
            class="filter-count">{{ filterCount }}</span>
        </div>
        <div class="divider-vertical" />
        <QuoteButton />
        <div class="divider-vertical" />
        <div class="divider-vertical m-l-auto" />
        <div class="top-bar-item top-bar-item-currency">
          <el-dropdown
            :hide-on-click="true"
            @command="handleCurrencyChanged">
            <div class="dropdown-handler">
              <span class="el-dropdown-link has-text-weight-bold">
                {{ currency | currencyLabel }} <i class="el-icon-caret-bottom el-icon--right" />
              </span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="c in currencies"
                :key="c"
                :command="c">
                {{ c | currencyLabel }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="divider-vertical" />
        <div class="top-bar-item top-bar-item-map">
          <el-switch
            v-model="mapActive"
            :inactive-text="$t('views.search.map')"
            active-text="" />
        </div>
      </div>
    </div>

    <progress-indicator
      v-if="isSearching" />

    <div class="search-main">
      <div
        :class="{'map-active':mapActive}"
        class="property-list-wrapper">
        <i id="results-con" />
        <h3
          v-if="!isSearching && availability.results.length"
          class="is-size-5 has-text-primary has-text-weight-bold m-b-4x">
          {{
            $t(
              'views.search.properties-found-in',
              { count: totalPropertiesFormatted, location: locationQuery }
            )
          }}
        </h3>
        <h3
          v-if="isSearching"
          class="is-size-5 has-text-primary has-text-weight-bold m-b-4x">
          {{ $t('views.search.properties-searching') }}
        </h3>
        <PropertyList
          :nights="nights"
          :is-loading="isSearching"
          :availability="availability"
          :layout-mode="$mq.all"
          :map-active="mapActive"
          image-size="large"
          @selected="handleSelectedProperty" />
        <Pagination
          v-if="!isSearching && availability.results.length"
          :page="availability.pagination.page"
          :page-size="availability.pagination.pageSize"
          :total-items="availability.pagination.totalItems"
          @change="handleSearch" />
        <CopyRight class="has-text-primary is-size-7 has-text-centered" />
      </div>
      <!-- map and filters drawer -->
      <transition name="view-fade">
        <div
          v-if="mapActive"
          class="map-wrapper">
          <div
            id="map"
            style="width: 100%;height: 100%;"
            :is-loading="isSearching">
            <!-- Google map here. -->
            <!--            <SearchMapCanvas-->
            <!--                :is-loading="isSearching"-->
            <!--                :results="availability.results"-->
            <!--                @searchArea="handleSearchArea"/>-->
            <Map
              :center="center"
              :google="google"
              :list="availability.results"
              :selected-property="$store.state.searchMany.selected"
              @searchArea="handleSearchArea"
              @markerSelect="handleMarkerSelect" />
          </div>
        </div>
      </transition>
    </div>
    <el-drawer
      :title="$t('views.search.update-search')"
      :visible.sync="searchDrawerActive"
      direction="ltr"
      size="100"
      @closed="handleSearchClosed">
      <div class="drawer-content">
        <SearchForm
          mode="vertical"
          @search="handleSearch" />
      </div>
    </el-drawer>
    <el-drawer
      destroy-on-close
      :title="$t('views.search.filters')"
      :visible.sync="filterDrawerActive"
      direction="ltr"
      size="100"
      @closed="handleFilterClosed">
      <div class="drawer-content">
        <SearchManyFilters
          ref="filters"
          @search="handleApplyFilter" />
        <div class="drawer-footer">
          <el-button
            class="button-secondary-outline"
            @click="handleClearFilter">
            {{ $t('views.search.clear') }}
          </el-button>
          <el-button
            class="button-secondary"
            style="min-width: 200px;"
            @click="handleApplyFilter">
            {{ $t('views.search.apply-filter') }}
          </el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { activity } from '@/common'
import { serialize } from '@/plugins/util'

import IconSearch from '../../components/icons/IconSearch'
import IconFilter from '../../components/icons/IconFilter'
import PropertyList from '../../components/PropertyList'
import Pagination from '@/components/Pagination'
// import SearchMapCanvas from './SearchMapCanvas'
import Map from '@/components/map/Map'
import SearchForm from '../share/SearchForm'
import SearchManyFilters from '../../components/SearchManyFilters'
import CopyRight from '../share/CopyRight'
import smoothScroll from '@/plugins/smoothScroll'
import QuoteButton from '../../components/QuoteButton'
import axios from 'axios'
import ProgressIndicator from '@/components/ProgressIndicator'

const TYPES_LIST = {
  CITY: 'city',
  POI: 'poi',
  HOTEL: 'hotel',
  AIRPORT: 'airport',
  METROCODE: 'metrocode'
}

export default {
  name: 'Search',
  components: {
    QuoteButton,
    CopyRight,
    SearchManyFilters,
    SearchForm,
    PropertyList,
    IconFilter,
    IconSearch,
    Pagination,
    Map,
    // SearchMapCanvas
    ProgressIndicator
  },
  props: {
    query: Object,
    params: Object
  },
  data () {
    return {
      isFilterApplied: false,
      sorting: { sortBy: 'popular' },
      mapActive: true,
      searchDrawerActive: false,
      filterDrawerActive: false
    }
  },
  computed: {
    ...mapState('searchMany', [
      'isSearching',
      'availability',
      'queryParams',
      'nights'
    ]),
    ...mapState('google', ['center', 'google']),
    ...mapGetters('searchFilters', ['filters', 'hasFilter', 'filterCount']),
    ...mapState('quoteSelector', { quoteEnabled: 'selected' }),
    currencies () {
      return this.$store.getters['app/supportedCurrencies']
    },
    childrenCount () {
      if (!this.queryParams.children || this.queryParams.children === undefined) {
        return 0
      }
      return this.queryParams.children.split(',').length
    },
    sortingLabel () {
      switch (this.sorting.sortBy) {
      case 'popular':
        return this.$t('views.search.popularity')
      case 'priceDsc':
        return this.$t('views.search.price-lowest-first')
      case 'priceAsc':
        return this.$t('views.search.price-highest-first')
      case 'adjustment':
        return this.$t('views.search.discount')
      case 'trustYouDsc':
        return this.$t('views.search.reviews')
      case 'bookmark':
        return this.$t('views.search.bookmark')
      default:
        return ''
      }
    },
    totalPropertiesFormatted () {
      if (this.availability.pagination === undefined) return 0
      return this.$options.filters.number(this.availability.pagination.totalItems)
    },
    locationQuery () {
      return this.queryParams.locationQuery
    },
    currency () {
      return this.queryParams.currency
    }
  },
  watch: {
    '$route': {
      handler (to, from) {
        if (from.name === 'homepage') {
          this.$store.commit('searchMany/SET_QUERY_PARAMS', { ...to.query, ...to.params })
          /**
           * Optimize for pre-rendering
           * Check vue.config.js PrerenderSPAPlugin for more details
           */
          if (to.name === 'search' && this.params.locationQuery !== 'blank') {
            this.$store.dispatch('searchMany/search', to.query)
          }
        }
      }
    },
    'isSearching': {
      handler (nV) {
      }
    }
  },
  created () {
    this.init()
  },
  mounted () {
    this.$store.commit('searchMany/SET_SEARCH_MANY', { isSearching: true })

    this.initAfterMounted()
  },
  activated () {
    this.initAfterActivated()
  },
  methods: {
    async getCenter () {
      let center
      if (this.queryParams.lng && this.queryParams.lat) {
        center = {
          lng: parseFloat(this.queryParams.lng),
          lat: parseFloat(this.queryParams.lat)
        }
      } else {
        try {
          const autoSuggestAPI = await axios(
            {
              url: `${process.env.VUE_APP_STATIC_DATA_SERVICE_URL}/api/regions/details`,
              params: {
                id: this.queryParams.regionId,
                type: this.queryParams.type
              },
              headers: {
                'x-api-key': process.env.VUE_APP_STATIC_DATA_API_KEY
              }
            })
          const autoSuggestAirportAPI = await axios(
            {
              url: `${process.env.VUE_APP_STATIC_DATA_SERVICE_URL}/api/autosuggest/airports`,
              params: {
                term: this.queryParams.locationQuery,
                limit: 3,
                locale: this.$i18n.locale.toLowerCase(),
                types: [TYPES_LIST.AIRPORT, TYPES_LIST.METROCODE].join()
              },
              headers: {
                'x-api-key': process.env.VUE_APP_STATIC_DATA_API_KEY
              }
            })
          const [hotelResponse, airportResponse] = await Promise.all([autoSuggestAPI, autoSuggestAirportAPI])
          const data = hotelResponse.data
          const airport = airportResponse.data
          const results = []
          if (data) {
            results.push(data)
          }
          if (airport) {
            const filteredAirport = airport.map((item) => {
              const isGroups = !!item.groups
              if (isGroups) {
                return item.groups.find((group) => {
                  const locationQuery = this.queryParams.locationQuery
                  const query = locationQuery
                  const name = group.name || ''
                  return name === query
                })
              }
              return item
            }).filter(Boolean)

            results.push(...filteredAirport)
          }

          for (const result of results) {
            if (Number(result.id) === Number(this.queryParams.regionId)) {
              center = {
                lng: parseFloat(result.data.longitude),
                lat: parseFloat(result.data.latitude)
              }
              break
            }
          }
        } catch (err) {
          // Ignore the error
          console.error(err)
        }
      }
      if (center) this.$store.commit('google/SET_DATA', { center: center })
    },
    async handleSearch (val) {
      // empty results ,bug fix ap-333 #2
      this.$store.commit('searchMany/SET_SEARCH_MANY', { availability: { results: [], pagination: {} } })
      // hide drawer
      this.searchDrawerActive = false
      let newQuery = {
        ...this.$route.query,
        ...this.queryParams,
        ...{
          lng: undefined,
          lat: undefined,
          radius: undefined,
          location: undefined
        },
        page: 1,
        ...val
      }

      if (newQuery.hasOwnProperty('propertyName') &&
          this.$route.query.regionId !== newQuery.regionId) {
        newQuery.propertyName = ''
      }
      const { regionName, locationQuery, ...query } = newQuery
      this.$store.commit('searchMany/SET_QUERY_PARAMS', newQuery)
      await this.$store.dispatch('searchMany/search', query)
      if (this.$route.query.regionId !== newQuery.regionId) {
        this.getCenter()
      }
      this.$router.replace({
        name: 'search',
        params: {
          locationQuery: regionName || locationQuery
        },
        query
      })
    },
    handleSelectedProperty ({ property, packages }) {
      const joinedGallery = [].concat(
        [{ s: property.heroImage, m: property.heroImage }],
        property.gallery)
      const payload = {
        property: { ...property, gallery: joinedGallery },
        availability: { packages },
        reviews: property.reviews
      }
      this.$store.commit('searchOne/SET_SEARCH_ONE', payload)
      const searchOneQuery = {
        checkInDate: this.query.checkInDate,
        checkOutDate: this.query.checkOutDate,
        nationality: this.query.nationality,
        adultCount: this.query.adultCount,
        children: this.query.children,
        roomCount: this.query.roomCount,
        currency: this.query.currency,
        regionId: this.query.regionId,
        propertyCode: property.propertyCode,
        locationQuery: this.$route.params.locationQuery
      }
      const activityPayload = {
        activityName: activity.viewProperty,
        details: {
          url: `${window.location.origin}/property/${property.name}${serialize(searchOneQuery)}`,
          propertyName: property.name,
          checkInDate: this.query.checkInDate,
          checkOutDate: this.query.checkOutDate,
          displayRate: packages[0].displayRate,
          heroImage: property.heroImage
        }
      }
      this.$store.dispatch('user/postActivity', activityPayload)
    },
    // handleFilterChanged (val) {
    //   let prevPropertyName = this.filters.propertyName || ''
    //   const propertyNameChanged = val.hasOwnProperty('propertyName') && val.propertyName !== prevPropertyName
    //
    //   this.filters = JSON.parse(JSON.stringify({ ...this.filters, ...val }))
    //   if (propertyNameChanged) {
    //     this.filterDrawerActive = false
    //     this.handleSearch(this.filters)
    //   }
    // },
    handleSortChanged (val) {
      this.sorting = { sortBy: val }
      this.handleSearch({ ...this.$route.query, ...this.sorting })
    },
    handleCurrencyChanged (currency) {
      this.handleSearch({ ...this.$route.query, currency })
    },
    handleClearFilter () {
      if (this.hasFilter) {
        this.$store.commit('searchFilters/CLEAR_DATA')
      }
      this.filterDrawerActive = false
      this.isFilterApplied = true
      this.handleSearch(this.filters)
    },
    /* handleUpdateSearchForm (val) {
      if (this.hasFilter) {
        this.$store.commit('searchFilters/CLEAR_DATA') // remove all filter for new search or update search form
        this.handleSearch({ ...val, ...this.filters })
      } else {
        this.handleSearch(val)
      }
    }, */
    handleApplyFilter () {
      this.isFilterApplied = true
      this.filterDrawerActive = false
      this.handleSearch(this.filters)
    },
    handleFilterClosed () {
      if (!this.isFilterApplied) {
        this.$store.commit('searchFilters/SET_DATA', { ...this.$route.query })
      }
      this.isFilterApplied = false
    },
    handleSearchArea (val) {
      this.$store.commit('searchFilters/UPDATE_DATA', {
        locationDistance: {
          location: undefined,
          radius: undefined,
          lng: undefined,
          lat: undefined
        }
      })
      this.handleSearch({
        searchId: this.$newSearchId(),
        location: undefined,
        ...val
      })
    },
    handleMarkerSelect (propertyCode) {
      this.$store.commit('searchMany/SET_SEARCH_MANY', { selected: propertyCode })
      if (this.$mq.tablet) smoothScroll.scrollTo(`#pc${propertyCode}`, { offset: -150 })
    },
    handleSearchClosed () {
      const payload = {
        ...this.queryParams,
        ...this.query,
        locationQuery: this.params.locationQuery
      }
      this.$store.commit('searchMany/SET_QUERY_PARAMS', payload)
    },
    async init () {
      if (this.query.sortBy) {
        this.sorting = { sortBy: this.query.sortBy }
      }
      if (!this.isSearching && this.availability.results.length === 0) {
        this.$store.commit(
          'searchMany/SET_QUERY_PARAMS',
          { ...this.query, ...this.params }
        )
        this.$store.commit('searchFilters/SET_DATA', { ...this.$route.query })
        /**
       * Optimize for pre-rendering
       * Check vue.config.js PrerenderSPAPlugin for more details
       */
        if (this.params.locationQuery !== 'blank') {
          this.$store.dispatch('searchMany/search', this.query)
        }
      }
    },
    async initAfterMounted () {
      const mapParams = {
        apiKey: process.env.VUE_APP_GOOLE_MAP_KEY,
        libraries: ['geometry', 'places']
      }
      const locale = this.$store.state.user.locale
      if (locale) {
        mapParams.language = locale
      }
      await this.$store.dispatch('google/loadGoogle', mapParams)
    },
    async initAfterActivated () {
      await this.getCenter()
    }
  },
  metaInfo () {
    const title = this.queryParams.locationQuery ? `Hotels in ${this.queryParams.locationQuery}` : ''
    return {
      title: title
    }
  }
}
</script>

<style lang="scss">
  @import "../../styles/bulma-variables";

  .top-bar {
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;
    position: sticky;
    top: 65px;
    z-index: $_jh_fullscreen-z-index+1;
    background-color: $white;

    &-inner {
      padding-left: $gap;
      padding-right: $gap;
      display: flex;
      flex-wrap: wrap;
    }

    &-item {
      display: flex;
      height: 58px;
      align-items: center;
      padding: 0 $bleed*6;

      &-search {
        padding-left: 0;
        cursor: pointer;
      }

      &-map {
        padding-right: 0;
      }

      &-filters {
        cursor: pointer;
      }

      .icon {
        margin-right: $bleed*3;
        color: $button-secondary-color;
      }
    }
  }

  .divider-vertical {
    border-right: 1px solid $border;
    @include mobile {
      display: none;
    }
  }

  .search-main {
    display: flex;
  }

  .map-wrapper {
    flex: 1 1 auto;
    position: sticky;
    top: 125px;
    height: calc(100vh - 125px);
  }

  .property-list-wrapper {
    padding: 1.5rem $gap;
    min-height: calc(100vh - 125px);
    flex: 1 1 auto;
    background-color: $white;

    &.map-active {
      flex: 1 1 auto;
      max-width: 640px;
      @include fullhd {
        max-width: 800px;
      }
    }
  }
  .sort-active {
    background-color: $color-4;
    color: $white;
  }

  .top-bar-item-sort, .top-bar-item-currency {
    padding: 0;

    .el-dropdown {
      height: 100%;
    }
  }

  .dropdown-handler {
    cursor: pointer;
    height: 100%;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .filter-count {
    display: inline-block;
    padding: 5px;
    line-height: 1;
    color: #fff;
    border-radius: 12px;
    background-color: $color-6;
    text-align: center;
    min-width: 1.7em;
    margin-left: 5px;
  }

  .sticky-progress-bar {
    position: sticky;
    top: 125px;
    z-index: $_jh_fullscreen-z-index+1;
  }

  .has-tou-bar {
    .top-bar {
      top: 101px;
    }
    .sticky-progress-bar {
      top: 161px;
    }
  }

  #map {
    position: sticky;
  }
</style>
