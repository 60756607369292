<template>
  <div style="display: none;">
    <slot v-if="ready" />
  </div>
</template>

<script>
import L from 'leaflet'
import 'leaflet.gridlayer.googlemutant'
import { findRealParent, propsBinder } from 'vue2-leaflet'

const props = {
  options: {
    type: Object,
    default () { return {} }
  },
  name: {
    type: String,
    default: ''
  },
  layerType: {
    type: String,
    default: 'base'
  },
  visible: {
    type: Boolean,
    default: true
  },
  center: {
    type: Object,
    default () {
      return {
        lat: 1.3100128416300763,
        lng: 103.88438311096195
      }
    }
  }
}
export default {
  props,
  data () {
    return {
      ready: false
    }
  },
  mounted () {
    this.mapObject = L.gridLayer.googleMutant(this.options)
    L.DomEvent.on(this.mapObject, this.$listeners)
    propsBinder(this, this.mapObject, props)
    this.ready = true
    this.parentContainer = findRealParent(this.$parent)
    this.parentContainer.addLayer(this, !this.visible)
    this.parentContainer.setCenter(this.center)
  },
  beforeDestroy () {
    this.parentContainer.removeLayer(this)
  },
  methods: {
    addLayer (layer, alreadyAdded) {
      if (!alreadyAdded) {
        this.mapObject.addLayer(layer.mapObject)
      }
    },
    removeLayer (layer, alreadyRemoved) {
      if (!alreadyRemoved) {
        this.mapObject.removeLayer(layer.mapObject)
      }
    }
  }
}
</script>
