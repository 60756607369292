export const tyGrades = {
  pos3: { key: 'pos3', css: 'pos', label: 'Excellent' },
  pos2: { key: 'pos2', css: 'pos', label: 'Very Good', max: 85, min: 80 },
  pos: { key: 'pos', css: 'pos', label: 'Good', max: 79, min: 75 },
  neu: { key: 'neu', css: 'neu', label: 'Fair', max: 74, min: 68 },
  neg: { key: 'neg', css: 'neg', label: 'Poor', max: 67, min: 0 },
  gy: { key: 'gy', css: 'gy', label: 'No Reviews', min: null, max: null }
}

export const starGrades = {
  s5: { key: 's5', css: 'pos', label: 'Five stars', rating: 5 },
  s4: { key: 's4', css: 'pos', label: 'Four stars', rating: 4 },
  s3: { key: 's3', css: 'pos', label: 'Three stars', rating: 3 },
  s2: { key: 's2', css: 'neu', label: 'Two stars', rating: 2 },
  s1: { key: 's1', css: 'neg', label: 'One star', rating: 1 },
  snull: { key: 'snull', css: 'gy', label: 'No rating', rating: null }
}

export const tyToGrade = (score) => {
  switch (true) {
  case score <= 67:
    return 'neg'
  case (score >= 68 && score <= 74):
    return 'neu'
  case (score >= 75 && score <= 79):
    return 'pos'
  case (score >= 80 && score <= 85):
    return 'pos2'
  case (score >= 86 && score <= 100):
    return 'pos3'
  default:
    return 'gy'
  }
}

export const activity = {
  viewProperty: 'VIEW_PROPERTY'
}

export const meals = {
  m1: { key: 'm1', css: 'score', label: 'Room only' },
  m2: { key: 'm2', css: 'score', label: 'Breakfast' },
  m3: { key: 'm3', css: 'score', label: 'Lunch' },
  m4: { key: 'm4', css: 'score', label: 'Dinner' },
  m5: { key: 'm5', css: 'score', label: 'Half board' },
  m6: { key: 'm6', css: 'score', label: 'Full board' },
  m7: { key: 'm7', css: 'score', label: 'All inclusive' }
}
