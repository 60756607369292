<template>
  <section class="many-filters">
    <FiltersPriceRange
      ref="filtersPriceRange"
      v-model="priceRange"
      :currency="currency"
      :max="maxPriceRange" />
    <PpGap />

    <FiltersPropertyName
      ref="filtersPropertyName"
      v-model="propertyName"
      @search="$emit('search')" />
    <PpGap />
    <FiltersLocationAddress
      v-if="google"
      ref="filtersLocationAddress"
      v-model="locationDistance"
      @search="$emit('search')" />
    <PpGap />
    <div class="columns is-mobile">
      <div class="column">
        <FiltersTrustYouRange
          ref="filtersTrustYouRange"
          v-model="tyRange"
          :layout-mode="layoutMode" />
      </div>
      <div class="column">
        <FiltersStarRange
          ref="filtersStarRange"
          v-model="starRange"
          :layout-mode="layoutMode" />
      </div>
    </div>
    <PpGap />
    <FiltersReservationPolicy
      ref="filtersReservationPolicy"
      v-model="reservationPolicy"
      :layout-mode="layoutMode" />
    <FiltersMeal
      ref="filtersMeals"
      v-model="meals"
      :layout-mode="layoutMode" />
    <PpGap />
    <FiltersQuickMenu
      ref="filtersCategories"
      v-model="categories"
      type="category"
      :layout-mode="layoutMode" />
    <PpGap />
  </section>
</template>

<script>
import FiltersMeal from '@/components/FiltersMeal'
import FiltersPriceRange from './FiltersPriceRange'
import FiltersTrustYouRange from './FiltersTrustYouRange'
import FiltersStarRating from './FiltersStarRating'
import FiltersPropertyName from './FiltersPropertyName'
import FiltersQuickMenu from './FiltersQuickMenu/FiltersQuickMenu'
import FiltersLocationAddress from './FiltersLocationAddress'
import { mapState, mapGetters } from 'vuex'
import { times, divide } from 'number-precision'
import FiltersReservationPolicy from '@/components/FiltersReservationPolicy'

export default {
  name: 'SearchManyFilters',
  components: {
    [FiltersPriceRange.name]: FiltersPriceRange,
    [FiltersTrustYouRange.name]: FiltersTrustYouRange,
    [FiltersStarRating.name]: FiltersStarRating,
    [FiltersPropertyName.name]: FiltersPropertyName,
    FiltersQuickMenu,
    FiltersLocationAddress,
    FiltersReservationPolicy,
    FiltersMeal
  },
  props: {
    layoutMode: {
      type: Array,
      default () {
        return []
      }
    }
    // showRemoveAll: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data () {
    return {
      timer: undefined,
      isMounted: false
    }
  },
  computed: {
    ...mapState('google', ['google']),
    reservationPolicy: {
      get () {
        return {
          refundable: this.$store.state.searchFilters.refundable,
          payAtHotel: this.$store.state.searchFilters.payAtHotel
        }
      },
      set (val) {
        this.$store.commit('searchFilters/UPDATE_DATA', {
          ...val
        })
      }
    },
    // refundable: {
    //   get () {
    //     return this.$store.state.searchFilters.refundable
    //   },
    //   set (val) {
    //     this.$store.commit('searchFilters/UPDATE_DATA', { refundable: val })
    //   }
    // },
    // payAtHotel: {
    //   get () {
    //     return this.$store.state.searchFilters.payAtHotel
    //   },
    //   set (val) {
    //     this.$store.commit('searchFilters/UPDATE_DATA', { payAtHotel: val })
    //   }
    // },
    propertyName: {
      get () {
        return this.$store.state.searchFilters.propertyName
      },
      set (val) {
        this.$store.commit('searchFilters/UPDATE_DATA', { propertyName: val })
      }
    },
    locationDistance: {
      get () {
        return { ...this.$store.state.searchFilters.locationDistance }
      },
      set (val) {
        this.$store.commit('searchFilters/UPDATE_DATA', { locationDistance: { ...val } })
      }
    },
    priceRange: {
      get () {
        if (this.$store.state.inNightlyAvgRate) {
          return this.$store.state.searchFilters.priceRange
        } else {
          let priceRange = []
          if (this.$store.state.searchFilters.priceRange.length && this.$store.state.searchMany.nights && this.$store.state.searchMany.roomCount) {
            // console.log(this.$store.state.searchFilters.priceRange)
            priceRange.push(this.$store.state.searchFilters.priceRange[0] !== Infinity && this.$store.state.searchFilters.priceRange[0] > 0 ? Math.round(times(this.$store.state.searchFilters.priceRange[0], this.$store.state.searchMany.nights, this.$store.state.searchMany.roomCount)) : this.$store.state.searchFilters.priceRange[0])
            priceRange.push(this.$store.state.searchFilters.priceRange[1] !== Infinity && this.$store.state.searchFilters.priceRange[1] > 0 ? Math.round(times(this.$store.state.searchFilters.priceRange[1], this.$store.state.searchMany.nights, this.$store.state.searchMany.roomCount)) : this.$store.state.searchFilters.priceRange[1])
          }
          return priceRange
        }
      },
      set (val) {
        if (this.$store.state.inNightlyAvgRate) {
          this.$store.commit('searchFilters/UPDATE_DATA', { 'priceRange': [...val] })
        } else {
          let priceRange = []
          if (val.length && this.$store.state.searchMany.nights && this.$store.state.searchMany.roomCount) {
            priceRange.push(val[0] !== Infinity && val[0] > 0 ? divide(val[0], this.$store.state.searchMany.nights, this.$store.state.searchMany.roomCount) : val[0])
            priceRange.push(val[1] !== Infinity && val[1] > 0 ? divide(val[1], this.$store.state.searchMany.nights, this.$store.state.searchMany.roomCount) : val[1])
          }
          this.$store.commit('searchFilters/UPDATE_DATA', { 'priceRange': [...priceRange] })
        }
        // this.$emit('filterChanged', {
        //   page: 1,
        //   priceRange: val.join(',')
        // })
      }
    },
    tyRange: {
      get () {
        return this.$store.state.searchFilters.tyRange
      },
      set (val) {
        // const actives = Object.entries(val).filter(([key, boo]) => boo)
        this.$store.commit('searchFilters/UPDATE_DATA', { 'tyRange': { ...val } })
        // this.$emit('filterChanged', {
        //   page: 1,
        //   tyRange: actives.map(([key, boo]) => key).join(',')
        // })
      }
    },
    starRange: {
      get () {
        return this.$store.state.searchFilters.starRange
      },
      set (val) {
        // const actives = Object.entries(val).filter(([key, boo]) => boo)
        this.$store.commit('searchFilters/UPDATE_DATA', { 'starRange': { ...val } })
        // this.$emit('filterChanged', {
        //   page: 1,
        //   starRange: actives.map(([key, boo]) => key).join(',')
        // })
      }
    },
    categories: {
      get () {
        return this.$store.state.searchFilters.categories
      },
      set (val) {
        // const actives = Object.entries(val).filter(([key, boo]) => boo)
        this.$store.commit('searchFilters/UPDATE_DATA', { 'categories': { ...val } })
        // this.$emit('filterChanged', {
        //   page: 1,
        //   categories: actives.map(([key, boo]) => key).join(',')
        // })
      }
    },
    meals: {
      get () {
        return this.$store.state.searchFilters.meals
      },
      set (val) {
        this.$store.commit('searchFilters/UPDATE_DATA', { 'meals': { ...val } })
      }
    },
    showClearFiltersButton () {
      if (!this.isMounted) {
        return false
      } else {
        return (this.$refs.filtersReservationPolicy.touched || this.$refs.filtersPriceRange.touched || this.$refs.filtersPropertyName.touched || this.$refs.filtersTrustYouRange.touched || this.$refs.filtersStarRange.touched || this.$refs.filtersCategories.touched || this.$refs.filtersLocationAddress.touched)
      }
    },
    ...mapState('meta', ['currency']),
    ...mapGetters('searchMany', ['maxPriceRange'])

  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    clearAllFilters () {
      this.$refs.filtersPriceRange.reset()
      this.$refs.filtersPropertyName.reset()
      this.$refs.filtersTrustYouRange.reset()
      this.$refs.filtersStarRange.reset()
      this.$refs.filtersCategories.reset()
      this.$refs.filtersReservationPolicy.reset()
      this.$refs.filtersMeals.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .remove-all-filter {
    padding: 6px 12px;

    //.el-button {
    //  display: block;
    // width: 100%;
    //}
  }
</style>
