<template>
  <div class="shadow-box filter-location-address">
    <div class="list is-gapless is-bottomless is-topless">
      <div class="list-title list-content">
        <span class="has-text-weight-bold is-size-5 has-text-primary">{{ $t('components.FiltersLocationAddress.filter-name') }}</span>
      </div>
      <div class="list-action">
        <el-button
          v-if="touched"
          type="text"
          class="has-text-secondary has-text-uppercase is-size-7"
          @click="handleClear">
          {{ $t('components.FiltersLocationAddress.clear') }}
        </el-button>
      </div>
    </div>
    <div class="list is-gapless is-topless is-bottomless">
      <div class="list-content">
        <form @submit.prevent="">
          <el-autocomplete
            v-model="local.location"
            name="property_location_filter"
            :placeholder="$t('components.FiltersLocationAddress.placeholder')"
            :fetch-suggestions="handleFetchSuggestions"
            :trigger-on-focus="false"
            :debounce="600"
            :loading="isFetchingSuggestion"
            style="width: 100%;"
            @select="handleSelect">
            <template slot-scope="{ item }">
              <div class="place-suggestion-item">
                {{ item.label }}
              </div>
            </template>
          </el-autocomplete>
          <div
            v-if="error"
            class="has-text-danger is-size-7"
            style="margin-top: 1px;">
            {{ error }}
          </div>
        </form>
      </div>
    </div>
    <div class="gap" />
    <div class="list is-gapless is-topless is-narrow space-between">
      <div class="list-action has-text-grey-light">
        {{ $t('components.FiltersLocationAddress.max-distance') }}
      </div>
      <div class="list-action has-text-grey-light">
        {{ max }} km
      </div>
    </div>
    <div class="list is-gapless is-topless is-narrow space-between">
      <div class="list-content">
        <el-slider
          v-model="local.radius"
          :min="min"
          :max="max"
          :disabled="!touched"
          @change="handleRadiusChanged" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getCountryByLocationQuery } from '@/plugins/countriesHelper'

export default {
  name: 'FiltersLocationAddress',
  props: {
    value: {
      type: Object,
      required: true
    },
    max: {
      type: Number,
      default: 10
    },
    min: {
      type: Number,
      default: 1
    },
    default: {
      type: Number,
      default: 2
    }
  },
  data () {
    return {
      local: {
        location: this.value.location,
        lng: this.value.lng,
        lat: this.value.lat,
        radius: parseInt(this.value.radius) / 1000 || this.default
      },
      submited: false,
      isFetchingSuggestion: false,
      placeService: undefined,
      autocompleteService: undefined,
      error: ''
    }
  },
  computed: {
    touched () {
      return !!this.local.lng || !!this.local.lng
    },
    ...mapState('google', ['google']),
    ...mapState('searchMany', [
      'queryParams'
    ])
  },
  watch: {
    'value': {
      immediate: true,
      deep: true,
      handler (nV) {
        this.local = {
          location: nV.location,
          lng: nV.lng,
          lat: nV.lat,
          radius: parseInt(nV.radius) / 1000 || this.default
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.placeService = new this.google.maps.places.PlacesService(document.createElement('div'))
      this.autocompleteService = new this.google.maps.places.AutocompleteService()
    })
  },
  methods: {
    reset () {
      this.local = { location: undefined, lng: undefined, lat: undefined, radius: this.max }
    },
    emitInput () {
      this.$emit('input', { ...this.local, radius: this.local.radius * 1000 })
    },
    handleClear () {
      this.reset()
      this.emitInput()
      this.visibility = false
    },
    handleFetchSuggestions (queryString, cb) {
      queryString = queryString.toLowerCase().trim()
      this.error = ''
      const blank = [
        {
          label: this.$t('app.no-result'),
          value: '',
          place_id: undefined
        }
      ]

      if (queryString !== '' && queryString.length > 1) {
        this.isFetchingSuggestion = true
        let request = {
          // types: ['address']
        }
        const locationArray = this.queryParams.locationQuery.split(',').map(item => item.toLowerCase().trim())
        const country = getCountryByLocationQuery(this.queryParams.locationQuery)
        const countryCode = country && country.a2 ? country.a2 : ''
        // when the city name contains special characters, just restrict country
        if (locationArray.length > 1 && (queryString.indexOf(`${locationArray[0]},`) === -1 || queryString.indexOf(`,${locationArray[0]}`) === -1) && /^[a-zA-Z\s]+$/.test(locationArray[0])) {
          request.input = `${queryString}, ${locationArray[0]}`
        } else {
          request.input = queryString
        }
        if (countryCode) {
          request.componentRestrictions = { country: countryCode }
        }

        this.autocompleteService.getPlacePredictions(request, (results, status) => {
          if (status === this.google.maps.places.PlacesServiceStatus.OK) {
            if (results.length) {
              return cb(results.map(item => {
                return {
                  label: item.description,
                  value: item.description,
                  place_id: item.place_id
                }
              }))
            } else {
              return cb(blank)
            }
          } else {
            if (status !== this.google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
              this.error = `Place API error: ${status}`
            }
            return cb(blank)
          }
        })
      }
    },
    handleSelect (item) {
      this.local.location = item.value
      this.placeService.getDetails({
        placeId: item.place_id,
        fields: ['geometry']
      }, (place, status) => {
        if (status === this.google.maps.places.PlacesServiceStatus.OK) {
          this.local.lng = place.geometry.location.lng()
          this.local.lat = place.geometry.location.lat()
          this.emitInput()
        } else {
          this.local.location = ''
          this.error = `Place API error: ${status}`
        }
      })
    },
    handleRadiusChanged () {
      this.emitInput()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  // .list-label {
  //   opacity: 0.68;
  // }

  .filter-location-address {
    //padding: $bleed $bleed * 2;
    .el-input__icon.el-icon-search {
      cursor: pointer;
      &.filter-location-address--submited,
      &:active {
        color: $link;
        transform: scale(1.3);
      }
    }
  }
  .filter-location-address--touched {
    font-weight: 600;
    color: $text;
  }
  .filter-location-address--untouched {
    opacity: 0.68;
  }
</style>
