<template>
  <div
    class="shadow-box filter-ty"
    :class="{'expanded':expanded}">
    <div
      class="list is-gapless is-bottomless is-topless  quick-menu-header"
      @click="handleToggleMenu()">
      <div class="list-title list-content">
        <span class="has-text-weight-bold has-text-primary is-size-5">{{ title }} <span
          v-show="count"
          class="count">{{ count }}</span></span>
      </div>
      <div class="list-action">
        <!--        <i class="el-icon-caret-bottom"></i>-->
        <el-button
          v-if="touched"
          type="text"
          class="has-text-secondary is-size-7 has-text-uppercase"
          @click.stop="handleClear">
          {{ $t('components.FiltersStarRating.clear') }}
        </el-button>
      </div>
    </div>
    <div class="list is-gapless quick-menu-content">
      <div class="list-content">
        <ul class="list-checkbox-half">
          <ListCheckBox
            v-for="it in dataList"
            :key="it.key"
            v-model="localValue[it.id]"
            @change="handleChanged">
            <span>{{ filterQuickMenuLabel(it) }}</span> <span> ({{ getQuickMenuCount(type,it.id) }})</span>
          </ListCheckBox>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { dataCategories, dataFacilities } from './data'
import ListCheckBox from '../ListCheckBox'
import { mapGetters } from 'vuex'

export default {
  name: 'FiltersQuickMenu',
  components: {
    ListCheckBox
  },
  props: {
    value: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    layoutMode: {
      type: Array,
      default () {
        return []
      }
    },
    type: {
      type: String,
      default: 'category'
    }
  },
  data () {
    return {
      localValue: {},
      timer: undefined,
      expanded: true
    }
  },
  computed: {
    touched () {
      return Object.values(this.localValue).filter(_ => _).length > 0
    },
    delay () {
      return this.layoutMode.includes('mobile') ? 0 : 800
    },
    dataList () {
      return this.type === 'category' ? dataCategories : dataFacilities
    },
    title () {
      return this.type === 'category' ? this.$t('components.FiltersQuickMenu.categories') : this.$t('components.FiltersQuickMenu.facilities')
    },
    count () {
      return Object.values(this.localValue).filter((v) => v).length
    },
    ...mapGetters('searchMany', ['getQuickMenuCount'])
  },
  watch: {
    'value': {
      immediate: true,
      handler (nV) {
        this.localValue = { ...nV }
      }
    }
  },
  methods: {
    reset () {
      this.localValue = {}
    },
    handleClear () {
      this.reset()
      this.$emit('input', this.localValue)
    },
    handleChanged () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$emit('input', this.localValue)
      }, this.delay)
    },
    handleToggleMenu () {
      // this.expanded = !this.expanded
    },
    filterQuickMenuLabel (value) {
      return this.$i18n.locale === 'zh-CN' ? value.cn : value.label
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';

  .list-label {
    opacity: 0.68;
  }

  .filter-ty {
   // padding: $bleed $bleed * 2;
  }

  .filter-ty--touched {
    font-weight: 600;
    color: $text;
  }

  .count {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background-color: $primary;
    color: #fff;
    text-align: center;
    line-height: 1.2rem;
    font-size: 0.7rem;
    margin-left: 0.2em;
    font-weight: 300;
  }

  .quick-menu-header {
    cursor: pointer;

  }

  .quick-menu-content {
    display: none;
  }

  .el-icon-caret-bottom {
    transition: all ease-in 0.2s;
  }

  .expanded {
    .quick-menu-content {
      display: block;
    }

    .quick-menu-header {
      .el-icon-caret-bottom {
        transform: rotate(180deg);
      }
    }
  }
</style>
<style lang="scss">
  .list-checkbox-half {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    > li {
      flex: 1 1 50%;
      width: 50%;
      padding-left: 0.75rem!important;
      padding-right: 0.75rem!important;
    }
  }
</style>
