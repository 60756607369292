<template>
  <div class="shadow-box filter-price">
    <div class="list is-gapless is-bottomless is-topless">
      <div class="list-title list-content">
        <span class="has-text-weight-bold has-text-primary is-size-5">{{ $store.state.inNightlyAvgRate ?$t('components.FiltersPriceRange.nightly-avg'):'Total Price' }}</span>
      </div>
      <div class="list-action">
        <a
          v-if="touched"
          type="text"
          class="has-text-secondary is-size-7"
          @click.prevent="handleClear">
          {{ $t('components.FiltersPriceRange.clear') }}
        </a>
        <!-- Nightly avg ({{ ccySymbol }}) -->
      </div>
    </div>
    <div class="list is-gapless is-topless is-bottomless">
      <div
        class="list-content"
        style="margin: 0 6px;">
        <el-slider
          v-model="priceRange"
          :min="min"
          :max="max"
          :show-tooltip="false"
          range
          @change="handleChanged" />
      </div>
    </div>
    <div class="list is-gapless is-topless is-narrow space-between">
      <div class="list-action">
        <!-- {{ min }} -->
        <div class="list-label is-size-7">
          {{ $t('components.FiltersPriceRange.min') }}
        </div>
        <div :class="[touched ? 'filter-price--touched' : 'filter-price--untouched']">
          <div class="price-range-input">
            <div class="prefix">
              {{ currency }}
            </div>
            <input
              :value="priceRange[0]"
              type="text"
              @input="handleInputMin">
          </div>
        </div>
      </div>
      <!--        <div class="list-content has-text-centered has-text-weight-semibold has-text-dark">-->
      <!-- {{ priceRange[0] }} – {{ priceRange[1] }} -->
      <!--        </div>-->
      <div class="list-action">
        <!-- {{ max }}+ -->
        <div class="list-label is-size-7">
          {{ $t('components.FiltersPriceRange.max') }}
        </div>
        <div :class="[touched ? 'filter-price--touched' : 'filter-price--untouched']">
          <div
            class="price-range-input"
            :class="{'has-suffix':priceRange[1]===max}">
            <div class="prefix">
              {{ currency }}
            </div>
            <input
              :value="priceRange[1]"
              type="text"
              @input="handleInputMax">
            <div
              v-show="priceRange[1]===max"
              class="suffix">
              +
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <PpGap size="big" />
      <div class="list is-gapless">
        <div class="list-content">
          <el-button type="text" class="has-text-danger" @click="handleClear">
            Clear
          </el-button>
        </div>
        <div class="list-action">
          <el-button type="primary" @click="handleApply">
            Apply
          </el-button>
        </div>
      </div> -->
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

const delay = 500

export default {
  name: 'FiltersPriceRange',
  props: {
    value: {
      type: Array,
      required: true
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 600
    },
    currency: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      priceRange: [this.min, this.max],
      visibility: false,
      local: this.value
    }
  },
  computed: {
    ccySymbol () {
      const kStr = this.toCurrency(99)
      return kStr.replace(/99/, '').trim()
    },
    touched () {
      return this.priceRange[0] !== this.min || this.priceRange[1] !== this.max
    },
    // minStr () {
    //   return this.value[0] === this.min ? this.min : this.toCurrency(this.value[0])
    // },
    minStrLocal () {
      return this.priceRange[0] === this.min ? this.min : this.toCurrency(this.priceRange[0])
    },
    infinityStrLocal () {
      return this.priceRange[1] === Infinity ? `${this.toCurrency(this.max)}+` : `${this.toCurrency(this.priceRange[1])}+`
    },
    // infinityStr () {
    //   return this.value[1] === Infinity ? `${this.toCurrency(this.max)}+` : `${this.toCurrency(this.value[1])}+`
    // },
    maxStrLocal () {
      return this.priceRange[1] === this.max || this.priceRange[1] === Infinity ? this.infinityStrLocal
        : this.toCurrency(this.priceRange[1])
    }
    // maxStr () {
    //   return this.priceRange[1] === this.max || this.value[1] === Infinity ? this.infinityStr :
    //   this.toCurrency(this.value[1])
    // },
    // localStr () {
    //   return isNaN(this.value[0]) ? 'Price ANY' : this.minStr + ' – ' + this.maxStr
    //   // return this.localSort.icon
    // }
  },
  watch: {
    'value': {
      immediate: true,
      deep: true,
      handler (nV) {
        if (nV.length === 2) {
          this.priceRange = nV
        } else {
          this.priceRange = [this.min, this.max]
        }
      }
    },
    'max': { // fix WL-182
      handler (nV) {
        if (this.value.length === 2) {
          this.priceRange = this.value
        } else {
          this.priceRange = [
            this.min,
            this.max
          ]
        }
      }
    }
  },
  methods: {
    toCurrency (val) {
      return this.$root.$options.filters.currency(val, { currency: this.currency, factionDigits: 0 })
    },
    reset () {
      this.priceRange = [this.min, this.max]
      // this.priceRange = [this.value[0] || this.min, this.value[1] || this.max]
    },
    handleClear () {
      this.$emit('input', [])
      this.reset()
      this.visibility = false
    },
    handleChanged () {
      if (this.priceRange[0] > this.priceRange[1]) {
        this.priceRange = [this.priceRange[1], this.priceRange[0]]
      }
      const min = this.priceRange[0]
      const max = this.priceRange[1] >= this.max ? Infinity : this.priceRange[1]
      const ex = [min, max]
      if (ex[0] === this.min && ex[1] === Infinity) {
        this.$emit('input', [])
      } else {
        this.$emit('input', ex)
      }
      this.visibility = false
    },
    handleInputMin: debounce(function (e) {
      let min = parseInt(e.target.value, 10)
      if (isNaN(min)) min = 0
      this.priceRange = [
        min,
        this.priceRange[1]
      ]
      this.handleChanged()
    }, delay),
    handleInputMax: debounce(function (e) {
      let max = parseInt(e.target.value, 10)
      if (isNaN(max)) max = this.max
      this.priceRange = [
        this.priceRange[0],
        max
      ]
      this.handleChanged()
    }, delay),
    opened () {
      const min = this.value[0] || this.min
      const max = this.value[1] === Infinity ? this.max : this.value[1] || this.max
      this.priceRange = [min, max]
    },
    closed () {
      this.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .list-label {
    opacity: 0.68;
  }
  .filter-price--touched {
    font-weight: 600;
    color: $text;
  }
  .filter-price--untouched {
    opacity: 0.68;
  }

  .price-range-input {
    position: relative;
    max-width: 100px;
    font-size: 12px;

    .prefix {
      position: absolute;
      left: 5px;
      top: 0;
      bottom: 0;
      height: 28px;
      line-height: 28px;
    }

    input {
      height: 28px;
      line-height: 28px;
      background-color: #FFFFFF;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #DCDFE6;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: #231f20;
      display: inline-block;
      font-size: inherit;
      outline: none;
      -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
      padding-right: 5px;
      padding-left: 40px;
      text-align: right;

      &:focus {
        border-color: #0094f0;
      }
    }

    .suffix {
      position: absolute;
      right: 5px;
      top: 0;
      bottom: 0;
      height: 28px;
      line-height: 27px;
    }

    &.has-suffix {
      input {
        padding-right: 12px;
      }
    }
  }

  .list-half {
    width: 50%;
  }
</style>
