<template>
  <g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 11C6.20914 11 8 9.20914 8 7C8 4.79086 6.20914 3 4 3C1.79086 3 0 4.79086 0 7C0 9.20914 1.79086 11 4 11ZM4 9C5.10457 9 6 8.10457 6 7C6 5.89543 5.10457 5 4 5C2.89543 5 2 5.89543 2 7C2 8.10457 2.89543 9 4 9Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 0C3.44772 0 3 0.447716 3 1V5.26756C3.29417 5.09739 3.63571 5 4 5C4.36429 5 4.70583 5.09739 5 5.26756V1C5 0.447715 4.55228 0 4 0ZM5 8.73244C4.70583 8.90261 4.36429 9 4 9C3.63571 9 3.29417 8.90261 3 8.73244V23C3 23.5523 3.44772 24 4 24C4.55228 24 5 23.5523 5 23V8.73244Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 13C10.7909 13 9 14.7909 9 17C9 19.2091 10.7909 21 13 21C15.2091 21 17 19.2091 17 17C17 14.7909 15.2091 13 13 13ZM13 15C11.8954 15 11 15.8954 11 17C11 18.1046 11.8954 19 13 19C14.1046 19 15 18.1046 15 17C15 15.8954 14.1046 15 13 15Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 24C13.5523 24 14 23.5523 14 23L14 18.7324C13.7058 18.9026 13.3643 19 13 19C12.6357 19 12.2942 18.9026 12 18.7324L12 23C12 23.5523 12.4477 24 13 24ZM12 15.2676C12.2942 15.0974 12.6357 15 13 15C13.3643 15 13.7058 15.0974 14 15.2676L14 1C14 0.447716 13.5523 3.91405e-08 13 8.74228e-08C12.4477 1.35705e-07 12 0.447716 12 1L12 15.2676Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 11C24.2091 11 26 9.20914 26 7C26 4.79086 24.2091 3 22 3C19.7909 3 18 4.79086 18 7C18 9.20914 19.7909 11 22 11ZM22 9C23.1046 9 24 8.10457 24 7C24 5.89543 23.1046 5 22 5C20.8954 5 20 5.89543 20 7C20 8.10457 20.8954 9 22 9Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 0C21.4477 0 21 0.447716 21 1V5.26756C21.2942 5.09739 21.6357 5 22 5C22.3643 5 22.7058 5.09739 23 5.26756V1C23 0.447715 22.5523 0 22 0ZM23 8.73244C22.7058 8.90261 22.3643 9 22 9C21.6357 9 21.2942 8.90261 21 8.73244V23C21 23.5523 21.4477 24 22 24C22.5523 24 23 23.5523 23 23V8.73244Z" />
  </g>
</template>
<script>
export default {
  name: 'IconFilter'
}
</script>
