<template>
  <div class="l-map-outer">
    <l-map
      ref="map"
      :zoom="zoom"
      :min-zoom="minZoom"
      :max-zoom="maxZoom"
      :center="centerArray"
      style="width: 100%;height: 100%"
      :options="{zoomControl: false}">
      <tilelayer-google
        v-if="google"
        :options="googleOptions"
        :center="center" />
      <l-tile-layer
        v-if="!google && shouldShowOtherMap"
        :url="url"
        :attribution="attribution"
        :options="tileLayerOptions" />
      <template v-for="(item) in list">
        <l-marker
          v-if="!!item.property && item.property.propertyCode"
          :ref="`marker-${item.property.propertyCode}`"
          :key="item.property.propertyCode"
          :options="{riseOnHover:true,riseOffset:300}"
          :lat-lng="[item.property.location.latLng.lat,item.property.location.latLng.lng]"
          @click="onMarkerClick(item.property,$event)">
          <l-icon :icon-size="iconSize">
            <div class="popup-tip-anchor">
              <div
                v-if="item.packages.length"
                class="popup-bubble-content">
                {{ adjustedDisplayRate(item) |currencyObj({factionDigits: 0}) }}
              </div>
              <div
                v-else
                class="popup-bubble-content">
                N.A
              </div>
            </div>
          </l-icon>
        </l-marker>
      </template>
      <l-marker
        v-if="$store.state.searchMany.queryParams.lat && $store.state.searchMany.queryParams.lng && $store.state.searchMany.queryParams.location"
        :lat-lng="locationSearchLatLng"
        :options="{riseOnHover:true,riseOffset:300}"
        @add="$nextTick(() => $event.target.openPopup())">
        <l-popup>{{ $store.state.searchMany.queryParams.location }}</l-popup>
      </l-marker>
      <l-control-zoom position="topright" />
      <l-control-scale position="bottomleft" />
    </l-map>
    <el-button
      type="primary"
      class="search-area-btn button-secondary"
      @click="handleSearchArea">
      {{ $t('components.google.search-this-area') }}
    </el-button>
  </div>
</template>

<script>
import L from 'leaflet'
import { LMap, LTileLayer, LMarker, LControlZoom, LIcon, LPopup, LControlScale } from 'vue2-leaflet'
import TilelayerGoogle from '@/components/map/TilelayerGoogle'

import 'leaflet/dist/leaflet.css'
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})
export default {
  name: 'Map',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControlZoom,
    LIcon,
    TilelayerGoogle,
    LPopup,
    LControlScale
  },
  props: {
    selectedProperty: {
      type: String,
      default: ''
    },
    google: {
      type: Object
    },
    googleOptions: {
      type: Object,
      default () {
        return {
          styles: [
            {
              'featureType': 'poi.business',
              'stylers': [
                {
                  'visibility': 'off'
                }
              ]
            },
            {
              'featureType': 'poi.park',
              'elementType': 'labels.text',
              'stylers': [
                {
                  'visibility': 'off'
                }
              ]
            }
          ]
        }
      }
    },
    mapBoxId: {
      type: String,
      default: 'mapbox/streets-v11'
    },
    mapBoxAccessToken: {
      type: String,
      default: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN || ''
    },
    center: {
      type: Object,
      default () {
        return {
          lat: 1.3100128416300763,
          lng: 103.88438311096195
        }
      }
    },
    zoom: {
      type: Number,
      default: 12
    },
    minZoom: {
      type: Number,
      default: 8
    },
    maxZoom: {
      type: Number,
      default: 19
    },
    list: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      iconSize: [40, 22],
      centerArray: [Number(this.center.lat), Number(this.center.lng)],
      shouldShowOtherMap: false
    }
  },
  computed: {
    locationSearchEnabled () {
      const { lng, lat, location } = this.$store.state.searchMany.queryParams
      return lat && lng && location
    },
    locationSearchLatLng () {
      const { lng, lat, location } = this.$store.state.searchMany.queryParams
      return lng && lat && location ? [Number(lat), Number(lng)] : undefined
    },
    latLngBounds () {
      return this.list.filter((item) => {
        const lat = Number(item.property.location.latLng.lat)
        const lng = Number(item.property.location.latLng.lng)
        if (lat && lng) {
          return true
        }
        return false
      }).map((item) => {
        const lat = Number(item.property.location.latLng.lat)
        const lng = Number(item.property.location.latLng.lng)
        return [lat, lng]
      })
    },
    latLngBoundsWithLocation () {
      let ret = [...this.latLngBounds]
      if (this.locationSearchLatLng) {
        ret.push(this.locationSearchLatLng)
      }
      return ret
    },
    url () {
      return this.mapBoxAccessToken ? 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}{r}?access_token={accessToken}' : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    },
    attribution () {
      return this.mapBoxAccessToken ? '&copy; <a href="https://www.mapbox.com/about/maps/" target="_blank">Mapbox</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors <a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a>' : '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    },
    tileLayerOptions () {
      return this.mapBoxAccessToken ? {
        tileSize: 512,
        maxZoom: 18,
        zoomOffset: -1,
        id: this.mapBoxId,
        accessToken: this.mapBoxAccessToken
      } : null
    }
  },
  watch: {
    center (nV) {
      if ((!this.latLngBounds || !this.latLngBounds.length) && nV) {
        const lat = Number(nV.lat)
        const lng = Number(nV.lng)
        this.centerArray = [lat, lng]
      }
    },
    selectedProperty (nV, oV) {
      this.$nextTick(() => {
        if (nV && this.$refs[`marker-${nV}`] && this.$refs[`marker-${nV}`].length) {
          let mapObj = this.$refs[`marker-${nV}`][0].mapObject
          mapObj.getElement().classList.add('selecting')
          mapObj.setZIndexOffset(230)
          if (this.canChangeCenterArray()) {
            const latLng = mapObj.getLatLng()
            const isLatLngArray = Array.isArray(latLng)
            this.centerArray = isLatLngArray ? latLng : [latLng.lat, latLng.lng]
          }
        }
        if (oV && this.$refs[`marker-${oV}`] && this.$refs[`marker-${oV}`].length) {
          let mapObj = this.$refs[`marker-${oV}`][0].mapObject
          mapObj.getElement().classList.remove('selecting')
          mapObj.setZIndexOffset(0)
        }
      })
    },
    latLngBoundsWithLocation (nV) {
      if (nV && nV.length && this.$refs.map) {
        this.lastFitBoundsTime = Date.now()
        const mapObject = this.$refs.map.mapObject
        // const bounds = mapObject.getBounds()
        // for (const coordinates of nV) {
        //   bounds.extend(coordinates)
        // }
        const bounds = L.latLngBounds(nV)
        try {
          // mapObject.fitBounds(bounds, { padding: [30, 30] })
          // prevent auto zoom
          mapObject.flyToBounds(bounds, { padding: [30, 30] })
          mapObject.panInsideBounds(bounds, { padding: [30, 30] })
          const center = bounds.getCenter()
          nV.length && mapObject.setView(center, this.zoom)
        } catch (error) {
          console.log(error)
        }
      }
    }
  },
  mounted () {
    // Google hasn't loaded in 5 seconds. Use other maps
    setTimeout(() => { this.shouldShowOtherMap = true }, 5000)
  },
  methods: {
    adjustedDisplayRate (item) {
      return this.$store.state.inNightlyAvgRate ? item.packages[0].agentUnitAdjustedDisplayRate : item.packages[0].agentAdjustedDisplayRate
    },
    onMarkerClick (property) {
      this.$emit('markerSelect', property.propertyCode)
    },
    handleSearchArea () {
      if (this.$refs.map) {
        const mapCenter = this.$refs.map.mapObject.getCenter()
        const southWest = this.$refs.map.mapObject.getBounds().getSouthWest()
        const radius = this.$refs.map.mapObject.distance(mapCenter, southWest)

        this.$emit('searchArea', {
          lat: mapCenter.lat,
          lng: mapCenter.lng,
          radius: radius || 1
        })
      }
    },
    /* Do not reset the center within 1 second after fitBounds, which can make the animation smoother. */
    canChangeCenterArray () {
      return (
        !this.lastFitBoundsTime || this.lastFitBoundsTime < Date.now() - 1000
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';

  .l-map-outer {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .search-area-btn {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 5000;
  }

  .popup-tip-anchor {
    position: relative;
    height: 2px;
    width: 40px;
    display: inline-block;
  }

  /* Draw the tip. */
  .popup-bubble-content::after, .popup-bubble-content::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 10px solid;
    border-right: 10px solid;
    border-top: 10px solid;
  }

  .popup-bubble-content::before {
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: $primary;
    transform: translateX(-50%);
  }

  .popup-bubble-content::after {
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: $white;
    transform: translate(-50%, -2px);
  }

  .selecting .popup-bubble-content::after {
    border-top-color: $primary; /* TIP_HEIGHT= */
  }

  .selecting .popup-bubble-content::before {
    border-top-color: $white;
  }

  /* The popup bubble itself. */
  .popup-bubble-content {
    position: absolute;
    left: 50%;
    bottom: 10px;
    display: inline-block;
    transform: translateX(-50%);
    /* Style the info window. */
    background-color: white;
    padding: 4px 5px;
    border-radius: $bleed;
    font-family: sans-serif;
    font-size: $size-6;
    border: 1px solid $primary;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    transition: 300ms transform cubic-bezier(0.175, 0.885, 0.32, 1.275);
    color: $primary;
    font-weight: 600;

    .selecting & {
      background-color: $primary;
      border: 1px solid $white;
      color: $white;
      font-weight: 600;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
      //transform: scale(1.2) translate(-20%, -92%) perspective(0px);
    }
  }
</style>
