<template>
  <div class="shadow-box filter-property-name">
    <div class="list is-gapless is-bottomless is-topless">
      <div class="list-title list-content">
        <span class="has-text-weight-bold is-size-5 has-text-primary">{{ $t('components.FiltersPropertyName.filter-name') }}</span>
      </div>
      <div class="list-action">
        <el-button
          v-if="touched"
          type="text"
          class="has-text-secondary has-text-uppercase is-size-7"
          @click="handleClear">
          {{ $t('components.FiltersPropertyName.clear') }}
        </el-button>
      </div>
    </div>
    <div class="list is-gapless is-topless is-bottomless">
      <div class="list-content">
        <form @submit.prevent="handleSubmit">
          <el-autocomplete
            v-model="local"
            :popper-append-to-body="false"
            name="property_name_filter"
            :placeholder="$t('components.FiltersPropertyName.local.placeholder')"
            :fetch-suggestions="handleFetchSuggestions"
            :trigger-on-focus="false"
            :debounce="600"
            :loading="isFetchingSuggestion"
            select-when-unmatched
            style="width: 100%;"
            @blur="handleBlur"
            @select="handleSubmit">
            <el-button
              v-if="$mq.mobile"
              slot="append"
              native-type="submit">
              {{ $t('components.FiltersPropertyName.go') }}
            </el-button>
            <i
              v-else
              slot="suffix"
              :class="['el-input__icon el-icon-search', submited ? 'filter-property-name--submited' : '']"
              @click="handleSubmit" />
          </el-autocomplete>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'FiltersPropertyName',
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      local: this.value[0],
      submited: false,
      isFetchingSuggestion: false
    }
  },
  computed: {
    touched () {
      return !!this.local
    },
    ...mapState('searchMany', ['queryParams'])
  },
  watch: {
    'value': {
      immediate: true,
      deep: true,
      handler (nV) {
        this.local = nV[0]
      }
    }
  },
  methods: {
    reset () {
      this.local = ''
    },
    handleFetchSuggestions (queryString, cb) {
      queryString = queryString.toLowerCase().trim()
      const blank = []

      if (queryString !== '' && queryString.length > 2) {
        this.isFetchingSuggestion = true
        this.$store.dispatch('searchFilters/propertySuggest', {
          term: queryString,
          regionId: this.queryParams.regionId,
          searchId: this.queryParams.searchId
        }).then(({ outlets, meta }) => {
          this.isFetchingSuggestion = false
          this.$store.commit('meta/SET_META_ACTION', meta)
          if (!outlets || outlets.results.length === 0) return cb(blank)
          return cb(outlets.results.map(name => {
            return {
              label: name,
              value: name
            }
          }))
        }).catch(err => {
          this.isFetchingSuggestion = false
          this.$store.commit('meta/SET_META_ACTION', err.meta)
          this.$store.commit(
            'SET_GLOBAL_MODAL',
            {
              show: true,
              content: err.message || (err.error && err.error.message) || ''
            }
          )
        })
      } else {
        return cb(blank)
      }
    },
    handleBlur () {
      this.$emit('input', [this.local])
      // Something user just easre the filter without submit it, this to show the user the filter actually still active
      // for agent app, not need this
      // setTimeout(() => {
      //   if (this.value !== this.local) {
      //     this.local = this.value
      //   }
      // }, 200)
    },
    handleClear () {
      this.$emit('input', [''])
      this.reset()
      this.visibility = false
    },
    handleSubmit () {
      // console.log('handleSubmit', this.local)
      this.$emit('input', [this.local])
      this.$emit('search')
      this.submited = true
      setTimeout(() => (this.submited = false), 200)
      // this.visibility = false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  // .list-label {
  //   opacity: 0.68;
  // }

  .filter-property-name {
    //padding: $bleed $bleed * 2;
    .el-input__icon.el-icon-search {
      cursor: pointer;
      &.filter-property-name--submited,
      &:active {
        color: $link;
        transform: scale(1.3);
      }
    }
  }
  .filter-property-name--touched {
    font-weight: 600;
    color: $text;
  }
  .filter-property-name--untouched {
    opacity: 0.68;
  }
</style>
