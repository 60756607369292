<template>
  <div class="shadow-box filter-ty">
    <div class="list is-gapless is-bottomless is-topless  quick-menu-header">
      <div class="list-title list-content">
        <span class="has-text-weight-bold has-text-primary is-size-5">{{ $t('components.FiltersRefundable.title') }}</span>
      </div>
      <div class="list-action">
        <el-button
          v-if="touched"
          type="text"
          class="has-text-secondary is-size-7 has-text-uppercase"
          @click.stop="handleClear">
          {{ $t('components.FiltersStarRating.clear') }}
        </el-button>
      </div>
    </div>
    <div class="list is-gapless quick-menu-content">
      <div class="list-content">
        <ul class="list-checkbox-half">
          <ListCheckBox
            v-model="refundable"
            @change="handleChanged">
            <span>{{ $t('components.FiltersRefundable.refundable') }}</span>
            <span> ({{ getRefundableCount('refundable','refundable') }})</span>
          </ListCheckBox>
          <ListCheckBox
            v-model="nonRefundable"
            @change="handleChanged">
            <span>{{ $t('components.FiltersRefundable.nonrefundable') }}</span>
            <span> ({{ getRefundableCount('refundable','nonRefundable') }})</span>
          </ListCheckBox>
          <ListCheckBox
            v-if="payAtHotelEnabled"
            v-model="payAtHotel"
            @change="handleChanged">
            <span>{{ $t('filters.payAtHotel') }}</span>
            <span> ({{ getPayAtHotelCount }})</span>
          </ListCheckBox>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ListCheckBox from './ListCheckBox'
import { mapGetters } from 'vuex'

export default {
  name: 'FiltersReservationPolicy',
  components: {
    ListCheckBox
  },
  i18n: {
    messages: {
      'en-US': {
        'title': 'Reservation policy',
        'Refundable': 'Refundable',
        'Non-refundable': 'Non-refundable'
      }
    }
  },
  props: {
    value: {
      type: Object,
      default () {
        return {}
      }
    },
    layoutMode: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      refundable: this.value.refundable === 'true',
      nonRefundable: this.value.refundable === 'false',
      payAtHotel: this.value.payAtHotel,
      timer: undefined
    }
  },
  computed: {
    touched () {
      return (this.refundable && !this.nonRefundable) || (this.refundable && !this.nonRefundable) ||
        (this.refundable && this.payAtHotel) ||
        (!this.refundable && this.payAtHotel) ||
        this.payAtHotel
    },
    delay () {
      return this.layoutMode.includes('mobile') ? 0 : 400
    },
    localValue () {
      let value = {
        refundable: undefined,
        payAtHotel: this.payAtHotel
      }
      if ((this.refundable && this.nonRefundable) || (!this.refundable && !this.nonRefundable)) {
        value.refundable = undefined
      } else if (this.refundable) {
        value.refundable = 'true'
      } else {
        value.refundable = 'false'
      }

      if (!this.payAtHotel) {
        value.payAtHotel = undefined
      }

      return value
    },
    ...mapGetters('searchMany', ['getRefundableCount', 'getPayAtHotelCount']),
    ...mapGetters('app', ['payAtHotelEnabled'])
  },
  watch: {
    'value.refundable': {
      immediate: true,
      handler (nV) {
        switch (nV) {
        case 'true':
          this.refundable = true
          this.nonRefundable = false
          break
        case 'false':
          this.refundable = false
          this.nonRefundable = true
          break
        default:
          this.refundable = false
          this.nonRefundable = false
        }
      }
    }
  },
  methods: {
    reset () {
      this.refundable = false
      this.nonRefundable = false
      this.payAtHotel = false
    },
    handleClear () {
      this.reset()
      this.$emit('input', this.localValue)
    },
    handleChanged () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$emit('input', this.localValue)
      }, this.delay)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .list-label {
    opacity: 0.68;
  }

  .filter-ty {
   // padding: $bleed $bleed * 2;
  }

  .filter-ty--touched {
    font-weight: 600;
    color: $text;
  }

  .count {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background-color: $primary;
    color: #fff;
    text-align: center;
    line-height: 1.2rem;
    font-size: 0.7rem;
    margin-left: 0.2em;
    font-weight: 300;
  }

  .quick-menu-header {
    cursor: pointer;

  }
  .el-icon-caret-bottom {
    transition: all ease-in 0.2s;
  }

</style>
<style lang="scss">
  .list-checkbox-half {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    > li {
      flex: 1 1 50%;
      width: 50%;
      padding-left: 0.75rem!important;
      padding-right: 0.75rem!important;
    }
  }
</style>
