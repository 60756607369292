<template>
  <div class="property-list">
    <!-- <div
      v-if="isLoading"
      class="fixed-height fixed-height--spinner">
      <div class="property-list--spiner">
        <PpSpiner/>
      </div>
    </div> -->
    <div
      v-if="isLoading && !results.length"
      class="columns is-multiline">
      <div
        v-for="col in 4"
        :key="col"
        class="column"
        :class="`${mapActive ? 'is-12' : 'is-6'}`">
        <skeleton-loading>
          <row
            :gutter="{top: '10px', bottom: '10px'}">
            <column
              :span="10"
              :gutter="10">
              <square-skeleton :box-properties="{height: '175px', width: '215px'}" />
            </column>
            <column
              :span="20"
              :gutter="10">
              <square-skeleton
                :count="2"
                :box-properties="{bottom: '10px'}" />
              <square-skeleton :box-properties="{bottom: '50px'}" />
              <square-skeleton :box-properties="{width: '100px'}" />
            </column>
          </row>
        </skeleton-loading>
      </div>
    </div>
    <div class="fixed-height">
      <div class="columns is-multiline">
        <template v-for="it in results">
          <div
            v-if="!!it.property && it.property.propertyCode"
            :key="it.property.propertyCode"
            :class="[columnClass,{quoteEnabled:quoteEnabled}]"
            class="column">
            <QuoteCheckbox
              v-if="quoteEnabled"
              :property="it" />
            <router-link
              :id="'pc'+ it.property.propertyCode"
              :to="toLink(it.property)"
              :target="target"
              :class="{'selected':selected===it.property.propertyCode}"
              class="property-list-router-link"
              @click.native="handleSelected(it)"
              @mouseenter.native="handleHover(it)"
              @mouseleave.native.self="handleOut(it)">
              <component
                :is="propertyComponent"
                :layout-mode="$mq.all"
                :layout="layout"
                :image-size="imageSize"
                v-bind="it"
                :nights="nights"
                @heroImageClicked="handleHeroImageClicked" />
            </router-link>
          </div>
        </template>
      </div>

      <div
        v-show="!isLoading && results.length === 0">
        <h3 class="is-size-5 has-text-weight-bold has-text-black m-b-4x">
          {{ $t('title') }}
        </h3>
        <!--        <EmptyState-->
        <!--          v-if="results.length === 0"-->
        <!--          :is-loading="isLoading"-->
        <!--          :message="$t('components.PropertyList.message')"-->
        <!--          class="has-text-centered list-content"/>-->
        <!-- <div class="list-content has-text-centered">
          Sorry! We cloudn't find any properties for your search.
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import QuoteCheckbox from './QuoteCheckbox'

export default {
  name: 'PropertyList',
  components: {
    QuoteCheckbox,
    'Property': () => import(/* webpackChunkName: "tablet" */ './Property'),
    'PropertyMobile': () => import(/* webpackChunkName: "mobile" */ './PropertyMobile')
  },
  props: {
    availability: Object,
    nights: Number,
    isLoading: Boolean,
    imageSize: String,
    mapActive: Boolean,
    layout: String,
    layoutMode: {
      type: Array,
      default () {
        return []
      }
    }
  },
  i18n: {
    messages: {
      'en-US': {
        'title': 'Sorry! We couldn\'t find any properties for your search.'
      }
    }
  },
  data () {
    return {
      timer: undefined,
      columnClass: this.mapActive ? 'is-12' : 'is-6'
    }
  },
  computed: {
    target () {
      return this.layoutMode.includes('mobile') ? '_self' : '_blank'
    },
    propertyComponent () {
      return this.layoutMode.includes('mobile') ? 'PropertyMobile' : 'Property'
    },
    results () {
      return this.availability.results || []
    },
    ...mapState('quoteSelector', { quoteEnabled: 'selected' }),
    ...mapGetters('searchOne', [
      'searchOneProperty',
      'searchOnePackage',
      'searchOneReviews'
    ]),
    ...mapState('searchMany', ['selected'])
  },
  watch: {
    mapActive (nv) {
      this.columnClass = this.mapActive ? 'is-12' : 'is-6'
    }
  },
  methods: {
    toLink (property) {
      const { page, ...query } = this.$route.query
      return {
        name: 'property',
        params: {
          name: property.name
        },
        query: {
          ...query,
          propertyCode: property.propertyCode,
          locationQuery: this.$route.params.locationQuery
        }
      }
    },
    handleStop () {
      // Keep this. to prevent click event bubble up
    },
    handleOut () {
      clearTimeout(this.timer)
    },
    handleHover (val) {
      this.timer = setTimeout(() => {
        this.$store.commit('searchMany/SET_SEARCH_MANY', { selected: val.property.propertyCode })
      }, 500)
      // const CEvt = document.createEvent('CustomEvent')
      // CEvt.initCustomEvent('hover-item', false, false, { propertyCode: val.property.propertyCode })
      // body.dispatchEvent(CEvt)
    },
    handleSelected (val) {
      this.$emit('selected', val)
    },
    handleHeroImageClicked (val) {
      this.$emit('heroImageClicked', val)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .property-list-router-link {
    height: 100%;
    display: block;
  }
  .property-list {
    padding: $bleed;
  }

  .property-list--spiner {
    padding: 100px 40px;
  }

  .fixed-height {
    @include tablet {
      //min-height: 750px;
    }
    @include mobile {
      //min-height: 450px;
    }
    &--spinner {
      min-height: 100px;
    }
  }

  .isSelecting {
    box-shadow: $selected-box-shadow;
  }
  .quoteEnabled {
    position: relative;
    padding-left: 40px;
  }
</style>
<style lang="scss">
  @import '../styles/bulma-variables';

  .selected .property-card {
    box-shadow: $selected-box-shadow !important;
  }
</style>
