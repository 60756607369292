<template>
  <div class="shadow-box filter-ty">
    <div class="list is-gapless is-bottomless is-topless">
      <div class="list-title list-content">
        <span class="has-text-weight-bold has-text-primary is-size-5">{{ $t('components.FiltersStarRating.title') }}</span>
      </div>
      <div class="list-action">
        <el-button
          v-if="touched"
          type="text"
          class="has-text-secondary has-text-uppercase is-size-7"
          @click="handleClear">
          {{ $t('components.FiltersStarRating.clear') }}
        </el-button>
      </div>
    </div>
    <div class="list is-gapless">
      <div class="list-content">
        <ul>
          <ListCheckBox
            v-for="it in grades"
            :key="it.key"
            v-model="localValue[it.key]"
            @change="handleChanged">
            <span v-if="it.key === 'snull'">{{ $t(`common.${it.key}`) }}</span>
            <StarRatings
              v-else
              :ratings="it.rating"
              style="display: inline-block;" />
            <span> ({{ getStarRatingCount(it.rating) }})</span>
          </ListCheckBox>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { starGrades } from '../common'
import StarRatings from './StarRatings'
import ListCheckBox from './ListCheckBox'
import { mapGetters } from 'vuex'

export default {
  name: 'FiltersStarRange',
  components: {
    [StarRatings.name]: StarRatings,
    [ListCheckBox.name]: ListCheckBox
  },
  props: {
    value: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    layoutMode: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      localValue: {},
      timer: undefined,
      grades: Object.values(starGrades)
    }
  },
  computed: {
    touched () {
      return Object.values(this.localValue).filter(_ => _).length > 0
    },
    delay () {
      return this.layoutMode.includes('mobile') ? 0 : 800
    },
    ...mapGetters('searchMany', ['getStarRatingCount'])
  },
  watch: {
    'value': {
      immediate: true,
      handler (nV) {
        this.localValue = { ...nV }
      }
    }
  },
  methods: {
    reset () {
      this.localValue = {}
    },
    handleClear () {
      this.reset()
      this.$emit('input', this.localValue)
    },
    handleChanged () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$emit('input', this.localValue)
      }, this.delay)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .list-label {
    opacity: 0.68;
  }
  .filter-ty {
   // padding: $bleed $bleed * 2;
  }
  .filter-ty--touched {
    font-weight: 600;
    color: $text;
  }
</style>
